import * as React from 'react';

import newYork from '../../images/JAG-new-york.png';
import jersey from '../../images/JAG-jersey.png';
import penn from '../../images/JAG-penn.png';

const Opportunities: React.FC = () => {
	const locations = [
		{
			title: 'NEW YORK',
			text: 'Explore openings in the state of New York.',
			btn: 'View Careers',
			href: '/careers/?state=NY',
			img: newYork
		},
		{
			title: 'NEW JERSEY',
			text: 'Find open positions in New Jersey.',
			btn: 'View Careers',
			href: '/careers/?state=NJ',
			img: jersey
		},
		{
			title: 'PENNSYLVANIA',
			text: 'Discover available opportunities Pennsylvania.',
			btn: 'View Careers',
			href: '/careers/?state=PA',
			img: penn
		}
	];

	return (
		<section className={'my-20 px-5'}>
			<div className={'container mx-auto flex flex-col'}>
				<h2
					className={
						'pb-8 font-poppins text-[26px] font-bold text-body'
					}
				>
					OPEN POSITIONS AT JAG PHYSICAL THERAPY
				</h2>
				<div
					className={
						'flex flex-wrap gap-4 gap-y-8 max-lg:justify-center'
					}
				>
					{locations.map((loc) => {
						return (
							<a
								href={loc.href}
								className="max-w-[280px]"
								key={loc.title + '_key'}
							>
								<div className="flex h-full flex-col items-center gap-[26px] rounded-lg border border-[#E3E2E2] p-6">
									<img src={loc.img} alt="" />
									<div className="flex flex-col items-center gap-4">
										<p className="text-center font-poppins text-2xl font-bold tracking-[-0.759px] text-body">
											{loc.title}
										</p>
										<p className="text-center font-poppins text-base text-body">
											{loc.text}
										</p>
										<a
											className="main-button !py-2"
											href={loc.href}
										>
											{loc.btn}
										</a>
									</div>
								</div>
							</a>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default Opportunities;
