import './Map.css';
import * as React from 'react';
// @ts-ignore
import { USMap } from 'career-page-components';
interface mapProps {
	data: any;
}

const CTA: React.FC<mapProps> = (props: mapProps) => {
	React.useEffect(() => {
		const g = document.querySelector('.map g');
		g?.setAttribute('transform', 'matrix(2.2 0 0 2.2 -1200 -100)');
		const paths = document.querySelectorAll('.map path');
		paths.forEach((path) => {
			path.setAttribute('stroke-width', '1px');
			path.setAttribute('stroke', 'white');
		});
	}, []);

	const mapOptions = {
		fill: '#910811',
		onHoverFill: '#b80713',
		disabledFill: '#D2D2D2'
	};
	return (
		<section className={'my-20 px-5 text-primary'}>
			<div className={'map container mx-auto bg-[#4D4D4F] text-center'}>
				<USMap
					searchPath={'/careers/'}
					options={mapOptions}
					data={props.data}
				/>
			</div>
		</section>
	);
};

export default CTA;
