import * as React from 'react';
interface HeroImageProps {
	imgSrc: string;
}
const HeroImage: React.FC<HeroImageProps> = (props: HeroImageProps) => {
	return (
		<section
			className={
				'flex min-h-[472px] flex-col items-center justify-center gap-6 bg-cover bg-top md:min-h-[472px]'
			}
			style={{ backgroundImage: `url('${props.imgSrc}')` }}
		>
			<h1 className="text-center font-permanent-marker text-[84px] text-white max-md:text-6xl">
				CAREERS
			</h1>
			<a className="main-button" href="/careers/">
				View Career Opportunities
			</a>
		</section>
	);
};

export default HeroImage;
