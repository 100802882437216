import React from 'react';
import Layout from '../layouts/Layout';
import Map from '../components/Map/Map';
import { graphql, useStaticQuery } from 'gatsby';
import Opportunities from '../components/Opportunities/Opportunities';
import HeroImage from '../components/HeroImage/HeroImage';
import IndexHeroImg from '../images/JAG-home-hero-img-2.png';
import OurCulture from '../components/OurCulture/OurCulture';

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	return (
		<Layout title={'JAG Careers'} desc={'JAG Careers'}>
			<HeroImage imgSrc={IndexHeroImg} />
			<section className="my-20 px-5">
				<div className={'container mx-auto flex flex-col gap-4'}>
					<h2
						className={
							'font-poppins text-[26px] font-bold text-body max-md:text-3xl'
						}
					>
						ABOUT JAG PHYSICAL THERAPY CAREERS
					</h2>
					<p className={'font-poppins text-base font-light text-body'}>
						At JAG Physical Therapy, our vision is to be the
						Tri-State Area’s leading rehabilitation provider and the
						preferred workplace throughout New York, New Jersey, and
						Pennsylvania. Our family oriented atmosphere provides
						our employees with countless resources, opportunity for
						growth and development, and comprehensive benefits. Our
						team approach has proven to be rewarding to both our
						staff and our patients, fostering strong colleague
						relationships for the main goal of providing the highest
						quality of care resulting in the best clinical outcomes.
						We remain fully invested in the growth of our staff,
						providing professional development programs that support
						our team members’ careers and personal aspirations.
					</p>
					<p className={'font-poppins text-base font-light text-body'}>
						JAG Physical Therapy has career opportunities within
						both clinical and non-clinical settings. We pride
						ourselves on creating a dynamic and motivational work
						experience and encourage our employees to grow from
						within.
					</p>
				</div>
				<div className={'container mx-auto mt-12 flex flex-col gap-4'}>
					<h2
						className={
							'font-poppins text-[26px] font-bold text-body max-md:text-3xl'
						}
					>
						Before You Apply
					</h2>
					<p className={'font-poppins text-base font-light text-body'}>
						A few things to know before you apply for a position
						with JAG Physical Therapy:
					</p>
					<ul className="pl-10">
						<li
							className={
								'font-poppins text-base font-light text-body'
							}
						>
							You must be able to provide proof of legal
							authorization to work in the United States
						</li>
						<li
							className={
								'font-poppins text-base font-light text-body'
							}
						>
							A valid email address is required to apply through
							our online system, which will enable you to receive
							important updates related to your application
						</li>
						<li
							className={
								'font-poppins text-base font-light text-body'
							}
						>
							You should upload a professional resume including
							your contact information and current address
						</li>
					</ul>
				</div>
			</section>
			<Opportunities />
			<Map data={data} />
			<OurCulture />
		</Layout>
	);
};

export default IndexPage;
