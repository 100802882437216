import * as React from 'react';
import VideoPopup from '../VideoPopUp/VideoPopUp';

import stephanie from '../../images/JAG-stephanie.png';
import justin from '../../images/JAG-justin.png';
import saafir from '../../images/JAG-saafir.png';
import sean from '../../images/JAG-sean.png';
import andrea from '../../images/JAG-andrea.png';

const OurCulture: React.FC = () => {
	const people = [
		{
			name: 'Stephanie Loschaivo',
			title: 'Director of Athletic Training',
			img: stephanie,
			video: 'https://www.youtube.com/embed/0ELoN5NAHmc'
		},
		{
			name: 'Justin Delgado',
			title: 'Physical Therapist',
			img: justin,
			video: 'https://www.youtube.com/embed/h8mgAbo_4u0'
		},
		{
			name: 'Saafir Jenkins',
			title: 'VP of People Operations',
			img: saafir,
			video: 'https://www.youtube.com/embed/t0SXiANrp7g'
		},
		{
			name: 'Sean Thomas',
			title: 'Clinical Director',
			img: sean,
			video: 'https://www.youtube.com/embed/GO5H9ROuxKU'
		},
		{
			name: 'Andrea Miele',
			title: 'Director of Patient Access',
			img: andrea,
			video: 'https://www.youtube.com/embed/q4jx6Alq8nM'
		}
	];

	return (
		<section className={'my-20 px-5'}>
			<div className={'container mx-auto flex flex-col gap-4'}>
				<h2 className={'font-poppins text-[26px] font-bold text-body'}>
					Our Culture
				</h2>
				<p className=" font-poppins text-base font-light text-body">
					At JAG Physical Therapy, passion is crucial for success in
					healthcare, as every member is dedicated to helping out
					patients. We prioritize identifying issues, setting goals,
					and ensuring a positive client experience.
				</p>
				<p className="font-poppins text-base font-light text-body">
					The atmosphere at JAG Physical Therapy is supportive and
					feels like a family, fostering strong connections amongst
					the team. We believe in providing ample opportunities for
					professional and personal growth, recognizing that our
					success is tied to the development of our team.
				</p>
				<div
					className={
						'my-9 flex flex-wrap justify-center gap-x-2 gap-y-8 max-md:grid-cols-1'
					}
				>
					{people.map((person) => {
						return <VideoPopup person={person} />;
					})}
				</div>
			</div>
		</section>
	);
};

export default OurCulture;
